import React from 'react'
import { Helmet } from 'react-helmet'

const IndexPage = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
      fontFamily: 'Roboto',
      fontSize: '36px',
    }}
  >
    <Helmet>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:900"
        rel="stylesheet"
      />
    </Helmet>
    <h1>Deam is a Digital Design Agency</h1>
  </div>
)

export default IndexPage
